import React, {useEffect, useState} from "react"
import "./App.css"

interface ArcadeStateUpdate {
  event: "arcade-state-update"
  arcade: ArcadeSpec
}

interface ArcadeSpec {
  id: string
  description: string
  name: string
  steps: Step[]
}

interface Step {
  id: string
  isActive: boolean
  name: string
  order: 1
  type: 1
}

interface HotspotClick {
  eventName: "Hotspot Clicked"
  flowId: string
  flowName: string
  hotspotId: string
  hotspotLabel: string
  stepId: string
}

function isArcadeStateUpdate(msg: any): msg is ArcadeStateUpdate {
  return typeof msg === "object" && "event" in msg && msg.event === "arcade-state-update"
}

function isHotspotClick(msg: any): msg is HotspotClick {
  return typeof msg === "object" && "eventName" in msg && msg.eventName === "Hotspot Clicked"
}

// https://arcade.orgavision.com/?arcade=UBiXgWhxY33bafAaW4Pr,01JPkTCoEOMvPJR4Qq05&width=2848&height=1780&redirectUrl=https://sven-wiegand.de
const queryParams = () => {
  const queryParams = new URLSearchParams(window.location.search)
  const {
    arcade,
    width: widthStr,
    height: heightStr,
    redirectTitle,
    redirectText,
    redirectButton,
    redirectUrl,
    ...redirectParams
  } = Object.fromEntries(queryParams) as { [key in string]: string | undefined }
  const verify = (param: string | undefined, errorMsg: string, f: (p: string | undefined) => boolean = Boolean) => {
    if (!f(param)) {
      throw Error(errorMsg)
    }
  }
  verify(arcade, "arcade parameter missing")
  verify(widthStr, "width must be specified and must be a number", width => !isNaN(Number(width)))
  verify(heightStr, "height must be specified and must be a number", height => !isNaN(Number(height)))
  verify(redirectTitle, "redirectTitle parameter missing")
  verify(redirectText, "redirectText parameter missing")
  verify(redirectButton, "redirectButton parameter missing")
  verify(redirectUrl, "redirectUrl parameter missing")

  const redirectQueryParams = Object.keys(redirectParams).map(key => `${key}=${encodeURI(redirectParams[key]!)}`).join("&")
  return {
    arcades: arcade!.split(",").map(arcade => arcade.trim()),
    width: Number(widthStr!),
    height: Number(heightStr!),
    redirectTitle: redirectTitle!,
    redirectText: redirectText!,
    redirectButton: redirectButton!,
    redirectUrl: redirectUrl + (redirectQueryParams.length ? `?${redirectQueryParams}` : ""),
  }
}

function App() {
  try {
    return <Arcade {...queryParams()} />
  } catch (error) {
    if (typeof error === "string")
      return <ErrorPage errorMsg={error}/>
    if (error instanceof Error)
      return <ErrorPage errorMsg={error.message}/>
    else
      return <ErrorPage errorMsg="Unknown error"/>
  }
}

interface State {
  shownArcadeId: string
  activeStepId: string
  isLastStep: boolean
}

function Arcade({arcades, width, height, redirectTitle, redirectText, redirectButton, redirectUrl}: ReturnType<typeof queryParams>) {
  const [state, setState] = useState<State>()
  const [clickedStepId, setClickedStepId] = useState<string>()

  useEffect(() => {
    const processIncomingMsg = (evt: MessageEvent) => {
      if (evt.origin !== "https://demo.arcade.software" || !evt.isTrusted) return
      const msg = evt.data
      if (isArcadeStateUpdate(msg)) {
        const activeStepIndex = msg.arcade.steps.findIndex(step => step.isActive)
        setState({
          shownArcadeId: msg.arcade.id,
          activeStepId: msg.arcade.steps[activeStepIndex].id,
          isLastStep: activeStepIndex === msg.arcade.steps.length - 1
        })
      } else if (isHotspotClick(msg)) {
        setClickedStepId(msg.stepId)
      }
    }
    window.addEventListener("message", processIncomingMsg)
    return () => window.removeEventListener("message", processIncomingMsg)
  }, [])

  const arcadeIndex = state ? arcades.findIndex(arcadeId => arcadeId === state?.shownArcadeId) : 0
  const isLastArcade = arcadeIndex >= arcades.length - 1
  const isLastStepClicked = state?.isLastStep && clickedStepId === state?.activeStepId
  const reachedEndOfArcade = isLastStepClicked && isLastArcade
  const arcadeIdToShow = isLastStepClicked && !isLastArcade ? arcades[arcadeIndex + 1] : arcades[arcadeIndex]

  return (
    <>
      <ArcadeStyle width={width} height={height}/>
      <div className="arcade">
        <Iframe arcadeId={arcadeIdToShow}/>
        {reachedEndOfArcade &&
            <RedirectMessage
                title={redirectTitle}
                text={redirectText}
                button={redirectButton}
                url={redirectUrl}
            />
        }
      </div>
    </>
  )
}

function ArcadeStyle({width, height}: { width: number, height: number }) {
  const style = `
    .arcade {
      aspect-ratio: ${width}/${height};
    }
    @media (min-aspect-ratio: ${width}/${height}) {
      .arcade {
        height: ${height}px;
        max-height: 80%;
        width: auto;
      }
    }
    @media (max-aspect-ratio: ${width}/${height}) {
      .arcade {
        width: ${width}px;
        max-width: 80%;
        height: auto;
      }
    }
  `
  return <style>{style}</style>
}

function Iframe({arcadeId}: {arcadeId: string}) {
  return (
    <iframe
      className="arcade-layer"
      src={`https://demo.arcade.software/${arcadeId}?embed`}
      loading="lazy"
      title="Mockup"
    />
  )
}

function RedirectMessage({title, text, button, url}: { title: string, text: string, button: string, url: string }) {
  return (
    <div className="arcade-layer redirect-message-container">
      <h1>{title}</h1>
      <p className="subtitle">{text}</p>
      <a href={url} className="button">{button}</a>
      <div className="spacer"/>
    </div>
  )
}

function ErrorPage({errorMsg}: { errorMsg: string }) {
  const requiredParams = {
    arcade: "ID of the arcade to be shown; multiple arcade IDs can be provided separated by comma",
    width: "native width of the arcade in pixels",
    height: "native height of the arcade in pixels",
    redirectTitle: "title shown on the screen after the last step has been clicked",
    redirectText: "text shown on the screen after the last step has been clicked",
    redirectButton: "label of the button that redirects the user to the redirectUrl",
    redirectUrl: "URL to redirect the user to, when the last step of the last arcade is clicked",
  }
  type Param = keyof typeof requiredParams
  return (
    <div className="error">
      <h1>Error</h1>
      <p><strong>{errorMsg}</strong></p>
      <p>The following query parameters must be specified:</p>
      <ul>
        {(Object.keys(requiredParams) as Param[]).map(key => <li key={key}><strong>{key}:</strong> {requiredParams[key]}
        </li>)}
      </ul>
      <p>All other query parameters will be forwarded to the <code>redirectUrl</code>.</p>
    </div>
  )
}

export default App
